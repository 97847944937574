<template>
  <main>
    Accepting Invite...
  </main>
</template>

<script lang="ts">
export default {
  watch: {
    // '$route.query.email':{
    //   immediate: true,
    //   async handler(email){
    //     await this.$store.dispatch('user/signout');
    //     this.$store.commit('user/SET', {email});
    //   }
    // },
    '$store.state.user.user.email':{
      immediate: true,
      handler(){
        if(this.$store.state.user.user.email){
          this.$store.dispatch('user/tag', 'Accepted Desktop').then(() => {
            setTimeout(() => {
              this.$router.push({
                name: 'Home'
              })
            }, 2000);
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
  @import '../less/variables';
</style>
